function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  media: {
    root: path(ROOTS_DASHBOARD, '/media'),

    newVideo: path(ROOTS_DASHBOARD, '/media/video/new'),
    videos: path(ROOTS_DASHBOARD, '/media/videos'),

    newRole: path(ROOTS_DASHBOARD, '/media/role/new'),
    roles: path(ROOTS_DASHBOARD, '/media/roles'),

    newPrivilege: path(ROOTS_DASHBOARD, '/media/privilege/new'),
    privileges: path(ROOTS_DASHBOARD, '/media/privileges'),

    users: path(ROOTS_DASHBOARD, '/media/users'),

    caches: path(ROOTS_DASHBOARD, '/media/caches'),
  },
  dashboard: {
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
  },
};

export const PATH_DOCS = 'https://jmaster.io';
// export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';