import { handleRequest } from '../../utils/axios';

export const findMediaVideoAPI = async (data) => {
  const config = {
    url: '/video/search',
    method: 'POST',
    data
  };
  return handleRequest(config);
};

export const getMediaVideoByIdAPI = async (id) => {
  const config = {
    url: `/video/${id}`,
    method: 'GET'
  };
  return handleRequest(config);
};

export const createMediaVideoAPI = async (data) => {
  const { fileObj, ...others } = data;

  const formData = new FormData();
  formData.append("file", fileObj);
  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (const key in others) {
    formData.append(key, others[key]);
  }

  const config = {
    url: '/video/',
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return handleRequest(config);
};

export const updateMediaVideoAPI = async (data) => {
  const { fileObj, ...others } = data;

  const formData = new FormData();
  if (fileObj != null)
    formData.append("file", fileObj);
  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (const key in others) {
    formData.append(key, others[key]);
  }

  const config = {
    url: '/video/',
    method: 'PUT',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return handleRequest(config);
};

export const processMediaVideoAPI = async (id) => {
  const config = {
    url: `/video/process/${id}`,
    method: 'PUT'
  };
  return handleRequest(config);
};

export const deleteMediaVideoAPI = async (id) => {
  const config = {
    url: `/video/${id}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};

export const deleteMediaVideosAPI = async (ids) => {
  const config = {
    url: `/video/all/${ids.toString()}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};
