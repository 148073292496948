import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import mediaCacheReducer from './slices/media/media.cache';
import mediaPrivilegeReducer from './slices/media/media.privilege';
import mediaRoleReducer from './slices/media/media.role';
import mediaUserReducer from './slices/media/media.user';
import mediaVideoReducer from './slices/media/media.video';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error']
};

const rootReducer = combineReducers({
  mediaVideo: persistReducer({ ...rootPersistConfig, key: "media-video" }, mediaVideoReducer),
  mediaCache: persistReducer({ ...rootPersistConfig, key: "media-cache" }, mediaCacheReducer),
  mediaPrivilege: persistReducer({ ...rootPersistConfig, key: "media-privilege" }, mediaPrivilegeReducer),
  mediaRole: persistReducer({ ...rootPersistConfig, key: "media-role" }, mediaRoleReducer),
  mediaUser: persistReducer({ ...rootPersistConfig, key: "media-user" }, mediaUserReducer),
});

export { rootPersistConfig, rootReducer };

