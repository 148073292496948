import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 50, height: 50, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 300 300">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="cls1">
            <stop stopColor="#00aeef" />
          </linearGradient>
          <linearGradient id="cls2">
            <stop stopColor="#414042" />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <polygon fill="url(#cls1)" points="234.24 76.06 234.24 97.47 310.9 20.91 310.9 0 234.24 76.06" />
          <polygon
            fill="url(#cls1)"
            points="261.22 113.89 263.47 111.65 264.03 111.08 264.03 111.08 310.9 64.14 310.9 42.32 234.24 118.87 234.24 140.91 261.22 113.89 261.22 113.89"
          />
          <polygon fill="url(#cls1)" points="310.9 108.64 310.9 86.12 264.03 132.92 264.03 155.44 310.9 108.64" />
          <polygoy fill="url(#cls1)" points="264.03 176.85 264.03 198.29 310.9 151.28 310.9 130.04 264.03 176.85" />
          <polygon fill="url(#cls1)" points="264.03 220.59 264.03 242.03 310.9 195.02 310.9 173.79 264.03 220.59" />
          <path
            fill="url(#cls2)"
            d="M201.79,173.41l32.45-32.5L92.93,0l0,166.6h0q0,17.76-7.28,25.77t-20.1,8A37.09,37.09,0,0,1,45.87,195a80.84,80.84,0,0,1-17.62-15L0,211.45A94,94,0,0,0,26.65,233q16.16,9,40.05,9a89.51,89.51,0,0,0,30.14-4.81,60.25,60.25,0,0,0,22.87-14.12A62.19,62.19,0,0,0,134,200.38c3.29-8.93,5-15.84,5-28.07v-7.12h0l-.26-54.92Z"
          />
        </g>
      </svg>
    </Box>
  );
}
